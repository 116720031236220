'use client';

import { useHermes } from '@/auth/use-hermes';
import { BootstrapData } from '@/schema/bootstrap.schema';
import { AppEnv } from '@/store/store';
import * as amplitude from '@amplitude/analytics-browser';
import { EnrichmentPlugin } from '@amplitude/analytics-types';
import { Experiment, ExperimentClient } from '@amplitude/experiment-js-client';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import {
  createContext,
  useEffect,
  useState,
  type PropsWithChildren,
} from 'react';

const enabledEnvs = new Set<AppEnv>(['staging', 'production']);

interface AmplitudeProviderProps extends PropsWithChildren {
  appEnv: AppEnv;
  bootstrapData: BootstrapData;
}

function initSessionReplay() {
  const sessionReplayTracking = sessionReplayPlugin();
  amplitude.add(sessionReplayTracking);
}

// Set tenant user property on session start event
// https://community.amplitude.com/data-instrumentation-57/multi-tenancy-how-to-set-user-properties-before-tracking-events-alternatives-without-user-property-5070?tid=5070&postid=13450#post13450
const rewardsCentralUserPropertyPlugin = (
  tenant: string,
  environment: string,
): EnrichmentPlugin => {
  return {
    execute: async (event) => {
      event.user_properties = {
        ...event.user_properties,
        $setOnce: {
          app: 'rewards_central',
          platform: 'rc-us',
          environment,
          tenant,
          domain: 'client',
          tenantId: tenant, // keep tenantId for backward compatibility
        },
      };
      return event;
    },
  };
};

type AmplitudeProviderContextProps = {
  experiment: ExperimentClient | null;
};

export const AmplitudeProviderContext =
  createContext<AmplitudeProviderContextProps>({
    experiment: null,
  });

const AmplitudeContextProvider = ({
  experiment,
  children,
}: AmplitudeProviderContextProps & PropsWithChildren) => {
  const [value] = useState({ experiment });

  return (
    <AmplitudeProviderContext.Provider value={value}>
      {children}
    </AmplitudeProviderContext.Provider>
  );
};

function initAmplitude(appEnv: AppEnv, userId: string, tenantId: string) {
  const AMPLITUDE_ID =
    appEnv === 'production'
      ? '800b5aef550feb42e79d5f12084b1f2b'
      : 'f8d781d4545205ee9452129f64ec3c5a';

  if (!enabledEnvs.has(appEnv)) {
    return;
  }

  amplitude.init(AMPLITUDE_ID, {
    userId,
  });
}

export function AmplitudeProvider({
  appEnv,
  bootstrapData,
  children,
}: AmplitudeProviderProps) {
  const hermes = useHermes();
  const AMPLITUDE_ID =
    appEnv === 'production'
      ? '<TO_BE_UPDATED>'
      : 'f8d781d4545205ee9452129f64ec3c5a';

  const experiment = Experiment.initializeWithAmplitudeAnalytics(AMPLITUDE_ID, {
    debug: true,
    automaticExposureTracking: true, // Default to true for now, but let's set to true to ensure we don't lose exposure props for variant value
    exposureTrackingProvider: {
      track: (exposure) => {
        amplitude.track('$exposure', exposure);
      },
    },
  });

  async function initializeExperiment() {
    // To implement user properties for standardised segmentation variants - see here for more https://www.notion.so/kaligo/Amplitude-Standardised-Properties-Values-for-Events-11a31c5427fd802bb560f4a5c2424705?pvs=4#11a31c5427fd803bacc9e82461f5b412
    // Future consideration to move to groups once it moves out of beta
    await experiment?.start({
      user_properties: {
        platform: 'rc-us', // TODO - need to make this dynamic and easy to determine from 'rc-in' later on
        environment: appEnv as string,
        tenant: bootstrapData.tenantId,
      },
    });
  }

  useEffect(() => {
    const userId = hermes.user?.sub;

    if (!userId) {
      return;
    }

    amplitude.add(
      rewardsCentralUserPropertyPlugin(
        bootstrapData.tenantId,
        appEnv as string,
      ),
    );
    initSessionReplay();
    initAmplitude(appEnv, userId, bootstrapData.tenantId);
    initializeExperiment();
  }, [appEnv, bootstrapData, hermes.user?.sub]);

  return (
    <AmplitudeContextProvider experiment={experiment}>
      {children}
    </AmplitudeContextProvider>
  );
}
