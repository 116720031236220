const intToHex = (num: number) => {
  var hex = Math.round(num).toString(16);
  if (hex.length === 1) hex = '0' + hex;
  return hex;
};

const toRgb = (hex: string): [r: number, g: number, b: number] => {
  const [r0, r1, g0, g1, b0, b1] = hex as unknown as [
    string,
    string,
    string,
    string,
    string,
    string,
  ];

  return [
    Number.parseInt(r0 + r1, 16),
    Number.parseInt(g0 + g1, 16),
    Number.parseInt(b0 + b1, 16),
  ];
};

const getBrightness = (r: number, g: number, b: number): number => {
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

export const determineColorBrightness = (color: string): 'light' | 'dark' => {
  const hex = color.replace(/^#/, '');
  const rgb = toRgb(hex);
  const brightness = getBrightness(rgb[0], rgb[1], rgb[2]);
  return brightness >= 128 ? 'light' : 'dark';
};

const mixWhite = (color: string, opacity: number) => {
  const hex = color.replace(/^#/, '');
  const rgbColor = toRgb(hex);
  return (
    '#' +
    intToHex((1 - opacity) * rgbColor[0] + opacity * 0xff) +
    intToHex((1 - opacity) * rgbColor[1] + opacity * 0xff) +
    intToHex((1 - opacity) * rgbColor[2] + opacity * 0xff)
  );
};

const mixBlack = (color: string, opacity: number) => {
  const hex = color.replace(/^#/, '');
  const rgbColor = toRgb(hex);
  return (
    '#' +
    intToHex((1 - opacity) * rgbColor[0] + opacity * 0x00) +
    intToHex((1 - opacity) * rgbColor[1] + opacity * 0x00) +
    intToHex((1 - opacity) * rgbColor[2] + opacity * 0x00)
  );
};

export const generateColorPalette = (
  variant: string,
  value: string,
): string => {
  return `
    --rc-color-${variant}: ${value};
    --rc-color-${variant}-100: ${mixWhite(value, 0.97)};
    --rc-color-${variant}-200: ${mixWhite(value, 0.75)};
    --rc-color-${variant}-300: ${mixWhite(value, 0.5)};
    --rc-color-${variant}-400: ${mixWhite(value, 0.25)};
    --rc-color-${variant}-500: ${value};
    --rc-color-${variant}-600: ${mixBlack(value, 0.15)};
    --rc-color-${variant}-700: ${mixBlack(value, 0.3)};
    --rc-color-${variant}-800: ${mixBlack(value, 0.4)};
    --rc-color-${variant}-900: ${mixBlack(value, 0.5)};

  `;
};

export const extractCssVariablesValuePairs = (
  variant: string,
  value: string,
): string[][] => {
  const colorsPalette = generateColorPalette(variant, value);
  const sanitizedColorsPalette = colorsPalette
    .replace(/(\r\n|\n|\r|\s)/gm, '')
    .split(';');

  return sanitizedColorsPalette.map((colorPalette) => colorPalette.split(':'));
};

export const generateOverrideThemeStyles = (
  variant: string,
  value: string,
): void => {
  const cssVariablesValuePairs = extractCssVariablesValuePairs(variant, value);

  for (const [key, value] of cssVariablesValuePairs) {
    if (key) {
      document.documentElement.style.setProperty(key, value ?? null);
    }
  }
};

export const generateOverrideColor = (color: string, value: string): void => {
  document.documentElement.style.setProperty(color, value);
};
