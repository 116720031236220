'use client';
import { useHermes } from '@/auth/use-hermes';
import {
  StaticDocument,
  StaticDocumentArrayApiResponse,
  StaticDocumentCategory,
  staticDocumentCategorySchema,
  staticDocumentSchema,
} from '@/features/static-document/static-document.schema';
import { useAuthFetchKy } from '@/hooks/fetch/use-auth-fetch-ky';
import { queryKeys } from '@/queries/query-factory';
import { validateSchema } from '@/schema/validate-schema';
import { getClientUrl } from '@/utils/get-client-url';
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

const responseFormatHeaders = new Headers({ 'x-response-format': 'custom' });

export function useStaticDocument(category: StaticDocumentCategory) {
  const baseUrl = getClientUrl();
  const path = `${baseUrl}/api/static_documents/${category}`;
  const authFetch = useAuthFetchKy;
  const hermes = useHermes();

  const { isLoading, isError, data, error, isFetched, refetch } = useQuery({
    ...queryKeys.staticDocument.documentDetails(category),
    queryFn: async () => {
      const data = await authFetch
        .get(path, { headers: responseFormatHeaders })
        .json();

      return validateSchema({
        data: data,
        schemaName: 'static-documents-schema',
        zodSchema: staticDocumentSchema,
      });
    },
    retry: 3,
    enabled: hermes.loggedIn,
  });

  return {
    isLoading,
    isError,
    error,
    isFetched,
    staticDocuments: data,
    refetch,
  };
}

export function useTermsAndConditionsModalContent() {
  const baseUrl = getClientUrl();
  // TODO: update the filter params once BE support it
  const path = `${baseUrl}/api/static_documents`;
  const authFetch = useAuthFetchKy;
  const hermes = useHermes();

  return useQuery({
    ...queryKeys.staticDocument.termsAndConditionsModal,
    queryFn: async () => {
      const response: StaticDocumentArrayApiResponse = await authFetch
        .get(path, { headers: responseFormatHeaders })
        .json();

      return validateSchema({
        data: response.data,
        schemaName: 'static-documents-array-schema',
        zodSchema: z.array(staticDocumentSchema),
      });
    },
    select: termAndConditionsContentSelector,
    retry: 3,
    enabled: hermes.loggedIn,
  });
}

// we should use the standalone selector function
// e.g. make the function identity stable to avoid selector being executed
// every re-render
function termAndConditionsContentSelector(data: Array<StaticDocument>) {
  let result: {
    termsConditions: StaticDocument | null;
    privacyPolicy: StaticDocument | null;
    serviceTerms: StaticDocument | null;
    marketingPreferences: StaticDocument | null;
  } = {
    termsConditions: null,
    privacyPolicy: null,
    serviceTerms: null,
    marketingPreferences: null,
  };

  data.forEach((item) => {
    if (item.category === staticDocumentCategorySchema.enum.privacy_policy) {
      result.privacyPolicy = item;
    }
    if (item.category === staticDocumentCategorySchema.enum.service_terms) {
      result.serviceTerms = item;
    }
    if (item.category === staticDocumentCategorySchema.enum.terms_conditions) {
      result.termsConditions = item;
    }
    if (
      item.category === staticDocumentCategorySchema.enum.marketing_preferences
    ) {
      result.marketingPreferences = item;
    }
  });
  if (result.termsConditions && result.privacyPolicy && result.serviceTerms) {
    return result;
  }
  console.error('Missing T&C content', {
    termsConditions: !!result.termsConditions,
    privacyPolicy: !!result.privacyPolicy,
    serviceTerms: !!result.serviceTerms,
  });
  return null;
}
