'use client';

import { Todo } from 'global';
import { useTranslations } from 'next-intl';
import { ReactNode, useCallback, useEffect, useRef } from 'react';

import { AuthContext, AuthContextConfig } from '@/auth/auth-context';
import { hermesServiceEventType } from '@/auth/auth-type';
import { cleanPerfLog, getPerfLogData, writePerfLog } from '@/auth/auth-utils';
import { handleRedirectAfterLogin } from '@/auth/redirect-logic';
import { useLocaleMutation } from '@/hooks/locale-selector/use-locale-mutation';
import { useSentryMonitoring } from '@/hooks/monitoring/use-sentry-monitoring';
import { supportedLocales } from '@/i18n/constants';
import {
  usePathname,
  usePathnameWithLocalePrefix,
  useRouterWithoutLocalePrefix,
  useSearchParams,
} from '@/i18n/navigation';
import { Locale } from '@/i18n/types';
import { useCustomizationConfigStore } from '@/store/store';
import {
  useIsPublicPage,
  useIsSessionExpired,
  useIsStaticContentPage,
} from '@/utils/page-url';
import { isInitialized, metrics, setUser } from '@sentry/nextjs';

import { flightRoutes } from '../app/[locale]/travel/flights/routes';
import { useAuthStore } from '../app/auth-store';
import { type AuthService } from '../auth/auth-service';

function SetSentryUser({
  user,
  children,
}: {
  user: Todo;
  children: ReactNode;
}) {
  const { sub, tenantId } = user || { sub: null, tenantId: null };

  const isSentryReady = isInitialized();
  const isSentryUserSet = useRef<boolean>(false);
  useEffect(() => {
    if (!isSentryUserSet.current && isSentryReady && sub && tenantId) {
      /** note: concat tenantId with sub (user ID) to form the Sentry user ID */
      setUser({ id: `${tenantId}:${sub}` });
      isSentryUserSet.current = true;
    }
  }, [isSentryReady, sub, tenantId]);

  if (isInitialized()) {
    setUser({
      id: sub,
    });
  }

  return children;
}

export function AuthProvider({
  children,
  hermesConfig,
  shouldRedirect,
  locales,
}: {
  children: React.ReactNode;
  hermesConfig: AuthContextConfig;
  shouldRedirect: boolean;
  locales: typeof supportedLocales;
}) {
  const t = useTranslations('authProvider');
  const isSessionExpired = useIsSessionExpired();
  const isPublicPage = useIsPublicPage();
  const isStaticContentPage = useIsStaticContentPage();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const pathnameWithLocalePrefix = usePathnameWithLocalePrefix();
  const routerWithoutLocalePrefix = useRouterWithoutLocalePrefix();
  const localeMutation = useLocaleMutation();
  const { sentryLog } = useSentryMonitoring();
  const { updateShouldAllowHomeRedirect } = useCustomizationConfigStore(
    (state) => {
      return {
        updateShouldAllowHomeRedirect: state.updateShouldAllowHomeRedirect,
      };
    },
  );

  const isInitialized = useRef(false);

  const {
    dispatch,
    state: {
      user,
      accessToken,
      decodedAccessToken,
      userPreference,
      authService,
      authState,
      isOpenSessionExpiryModal,
    },
  } = useAuthStore();

  const canNotValidateText = t('canNotValidate');
  const timeoutText = t('timeout');

  const setIsOpenSessionExpiryModal = useCallback(
    (payload: boolean) =>
      dispatch({
        type: 'SET_IS_OPEN_SESSION_EXPIRY_MODAL',
        payload,
      }),
    [dispatch],
  );

  const getIdleDialogRemainingSeconds = useCallback(() => {
    if (authService) {
      return authService.getIdleDialogRemainingSeconds();
    }
    return null;
  }, [authService]);

  const login = useCallback(() => {
    if (authService) {
      authService.authLogin();
    }
  }, [authService]);

  const logout = useCallback(
    (config: Parameters<AuthService['authLogout']>[0]) => {
      if (authService) {
        authService.authLogout(config);
      }
    },
    [authService],
  );

  const sessionCheck = useCallback(() => {
    if (authService) {
      return authService.sessionCheck();
    }
  }, [authService]);

  const onIdleDialogClosed = useCallback(() => {
    if (authService) {
      authService.onIdleDialogClosed();
    }
  }, [authService]);

  useEffect(() => {
    // Do not redirect to login page if the user is on the failure page
    // to avoid redirect loop
    if (isPublicPage) {
      return;
    }

    // If it's neither a public page or a static content page
    // we need to trigger a check here to do a authentication check and login.
    if (!(isPublicPage || isStaticContentPage)) {
      sessionCheck()?.catch(() => {
        login();
      });
    }

    // with strict mode React.useEffect is called twice
    // https://react.dev/reference/react/StrictMode#fixing-bugs-found-by-re-running-effects-in-development
    if (isInitialized.current) {
      // do not initialize twice
      return;
    }
    isInitialized.current = true;
    // hermes is not designed for server side rendering
    // it's accessing the window on initialization
    // and throw an error if we static import it
    import('../auth/auth-service-static')
      .then(({ AuthServiceStatic }) => {
        console.log('DEBUG - auth: init');
        const initData = hermesConfig;
        AuthServiceStatic.init({
          ...initData,
          onStateChange: (newAuthState) => {
            switch (newAuthState.type) {
              case hermesServiceEventType.enum.login: {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                const userPreferredLocale = newAuthState.payload.userPreference
                  ?.locale as Locale;

                const redirectConfig = handleRedirectAfterLogin({
                  locale: userPreferredLocale,
                  pathname,
                  searchParams,
                  /**
                   * NOTE(SonTruongAscenda):
                   * Workaround solution: Hardcode Travel as the homepage for Robinhood and Payrewards
                   */
                  homePagePathname: shouldRedirect
                    ? flightRoutes.home
                    : undefined,
                  savedUrl:
                    newAuthState.payload.transactionAppState?.currentUrl,
                });

                if (redirectConfig.shouldRedirect) {
                  // use replace to avoid redirect loop
                  // for example A -> B -> C
                  // if we use push in C, the user will be redirected to B
                  // and then redirected to C again
                  routerWithoutLocalePrefix.replace(
                    redirectConfig.nextPathname,
                  );
                } else {
                  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                  const maybeLocale = pathnameWithLocalePrefix.split(
                    '/',
                  )[1] as Locale;

                  if (
                    maybeLocale !== userPreferredLocale &&
                    locales.includes(maybeLocale)
                  ) {
                    localeMutation.mutateAsync(maybeLocale).catch((error) => {
                      sentryLog.error(
                        new Error(
                          `Error while updating user preferred locale - ${maybeLocale} - ${error.toString()}`,
                          { cause: error },
                        ),
                      );
                    });
                  }
                }

                // We have to wait for the redirect to complete before we allow the home page redirect
                // otherwise the redirect login in the home page will override the pathname we replace above
                setTimeout(() => {
                  updateShouldAllowHomeRedirect(true);
                }, 2000);

                writePerfLog({
                  groupId: 'auth',
                  label: 'login success',
                });
                const authPerf = getPerfLogData();
                if (authPerf) {
                  metrics.distribution(
                    'authentication',
                    authPerf.total / 1000,
                    {
                      tags: { type: 'total' },
                      unit: 'second',
                    },
                  );
                }
                cleanPerfLog();
                dispatch({
                  type: 'SET_AUTH_STATE',
                  payload: newAuthState.type,
                });
                dispatch({
                  type: 'SET_AUTH_INFO',
                  payload: {
                    userPreference: newAuthState.payload.userPreference,
                    user: newAuthState.payload.user,
                    accessToken: newAuthState.payload.accessToken,
                    decodedAccessToken: newAuthState.payload.decodedAccessToken,
                  },
                });

                break;
              }
              case hermesServiceEventType.enum.update: {
                /** TODO(rongsen/sontruong):
                 * Ticket: https://kaligo.atlassian.net/browse/RCF-1900
                 *
                 * When we refresh a user token, there might be a user preferred locale change.
                 * Hence, we should update the current page locale WITHOUT REFRESHING IT to avoid bad user experiences.
                 *
                 * We need to check if next-intl library is able to change the locale without refreshing the page.
                 * If NOT, we should skip this functionality and explain why we don't update the locale here.
                 * If YES, we should update the locale WITHOUT REFRESHING THE PAGE when we refresh the user token.
                 */

                dispatch({
                  type: 'SET_AUTH_INFO',
                  payload: {
                    userPreference: newAuthState.payload.userPreference,
                    user: newAuthState.payload.user,
                    accessToken: newAuthState.payload.accessToken,
                    decodedAccessToken: newAuthState.payload.decodedAccessToken,
                  },
                });

                break;
              }
              case hermesServiceEventType.enum.logout: {
                if (isStaticContentPage) return;

                dispatch({
                  type: 'SET_AUTH_STATE',
                  payload: newAuthState.type,
                });
                // debugger;
                authService.authLogin();
                break;
              }
              case hermesServiceEventType.enum.openSessionExpiredDialog: {
                dispatch({
                  type: 'SET_IS_OPEN_SESSION_EXPIRY_MODAL',
                  payload: true,
                });
                break;
              }
              case hermesServiceEventType.enum.closeSessionExpiredDialog: {
                dispatch({
                  type: 'SET_IS_OPEN_SESSION_EXPIRY_MODAL',
                  payload: false,
                });
                break;
              }
            }
          },
          onError: (error) => {
            sentryLog.error(error);
          },
        });

        const authService = AuthServiceStatic.getInstance();

        dispatch({
          type: 'SET_AUTH_SERVICE',
          payload: authService,
        });
        // Do not init auth if the user is on the sessionExpired page
        if (isSessionExpired) {
          return;
        }

        authService.initAuth().catch((error) => {
          sentryLog.error(error);
          let errorMessage = error.message || canNotValidateText;
          // default code
          let code = error.code || 'FE0003';
          if (error.code === 'HM08') {
            errorMessage = timeoutText;
            code = 'HM08';
          }
          routerWithoutLocalePrefix.push(
            `/failure?code=${code}&error=${errorMessage}&httpCode=200`,
          );
        });
      })
      .catch((error) => {
        // TODO report sentry
        // Should we perform a hard refresh on the browser here?
        console.error('auth-service-static import error', error);
      });

    // We need to init the hermes service here
    // that's don't need to add authService to the dependency array
  }, [
    canNotValidateText,
    timeoutText,
    authState,
    dispatch,
    hermesConfig,
    isPublicPage,
    shouldRedirect,
    isSessionExpired,
    pathname,
    pathnameWithLocalePrefix,
    routerWithoutLocalePrefix,
    searchParams,
    sentryLog,
    updateShouldAllowHomeRedirect,
    localeMutation,
    locales,
  ]);

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        decodedAccessToken,
        authState,
        user,
        userPreference,
        getIdleDialogRemainingSeconds,
        login,
        logout,
        isOpenSessionExpiryModal,
        setIsOpenSessionExpiryModal,
        onIdleDialogClosed,
      }}
    >
      <SetSentryUser user={user}>{children}</SetSentryUser>
    </AuthContext.Provider>
  );
}
