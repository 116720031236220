'use client';
// copied from https://github.com/vercel/next.js/blob/canary/packages/third-parties/src/google/gtm.tsx
// the GoogleTagManager in the @next/third-parties lib doesn't support nonce
// https://github.com/vercel/next.js/pull/61820
// we copied the code here and added nonce support we should switch to the official version once nonce is released
// TODO: Evaluate import 'client only'
import Script from 'next/script';
import { useEffect } from 'react';

type GTMParams = {
  gtmId: string;
  dataLayer?: string[];
  dataLayerName?: string;
  auth?: string;
  nonce?: string;
  preview?: string;
};

let currDataLayerName: string | undefined = undefined;

export function GoogleTagManager(props: GTMParams) {
  const {
    gtmId,
    dataLayerName = 'dataLayer',
    auth,
    preview,
    dataLayer,
    nonce,
  } = props;

  if (currDataLayerName === undefined) {
    currDataLayerName = dataLayerName;
  }

  const gtmLayer = dataLayerName !== 'dataLayer' ? `&l=${dataLayerName}` : '';
  const gtmAuth = auth ? `&gtm_auth=${auth}` : '';
  const gtmPreview = preview ? `&gtm_preview=${preview}&gtm_cookies_win=x` : '';

  useEffect(() => {
    // performance.mark is being used as a feature use signal. While it is traditionally used for performance
    // benchmarking it is low overhead and thus considered safe to use in production and it is a widely available
    // existing API.
    // The performance measurement will be handled by Chrome Aurora

    performance.mark('mark_feature_usage', {
      detail: {
        feature: 'next-third-parties-gtm',
      },
    });
  }, []);

  return (
    <>
      <Script
        nonce={nonce}
        id="_next-gtm-init"
        dangerouslySetInnerHTML={{
          __html: `
      (function(w,l){
        w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        ${dataLayer ? `w[l].push(${JSON.stringify(dataLayer)})` : ''}
      })(window,'${dataLayerName}');`,
        }}
      />
      <Script
        nonce={nonce}
        id="_next-gtm"
        data-ntpc="GTM"
        src={`https://www.googletagmanager.com/gtm.js?id=${gtmId}${gtmLayer}${gtmAuth}${gtmPreview}`}
        strategy="lazyOnload"
      />
    </>
  );
}

export const sendGTMEvent = (data: Object) => {
  if (currDataLayerName === undefined) {
    console.warn(`@next/third-parties: GTM has not been initialized`);
    return;
  }

  if (currDataLayerName in window) {
    const dataLayer = window[currDataLayerName as keyof typeof window];

    if (Array.isArray(dataLayer)) {
      dataLayer.push(data);
    }
  } else {
    console.warn(
      `@next/third-parties: GTM dataLayer ${currDataLayerName} does not exist`,
    );
  }
};
