'use client';

import { usePathname } from '@/i18n/navigation';
import { useMount } from 'ahooks';
import userflow from 'userflow.js';
import UserFlowProvider from './userflow-provider';

export default function UserFlowRobinhoodProvider() {
  const pathname = usePathname();

  // Constraint to enable on robinhood
  // Re: https://kaligo.atlassian.net/browse/RPD-371
  const isAllowedPaths = pathname.includes('/travel/flights/review');

  useMount(() => {
    userflow.setResourceCenterLauncherHidden(true);
  });

  return isAllowedPaths ? <UserFlowProvider /> : null;
}
