'use client';

import { useSize } from 'ahooks';
import { WontFix } from 'global';
import { useTranslations } from 'next-intl';
import {
  AnchorHTMLAttributes,
  CSSProperties,
  DetailedHTMLProps,
  useContext,
} from 'react';

import { Container } from '@/components/container';
import { CurrencySelector } from '@/components/currency-selector/currency-selector';
import { customNavigation } from '@/components/header/utils';
import { LocaleSelector } from '@/components/locale-selector/locale-selector';
import { Markdown } from '@/components/markdown';
import { RcLink } from '@/components/rc-link';
import { RewardsCentralConfiguration } from '@/config-schema/rewards-central-config';
import { useTrackProductNavigation } from '@/hooks/amplitude/shared/use-track-product-navigation';
import { useTrackTravelNavigation } from '@/hooks/amplitude/travel/use-track-travel-navigation';
import { useTenantHubConfig } from '@/hooks/config/tenants/use-tenanthub-config';
import { useFooterRcLinks } from '@/hooks/footer/use-footer-rc-links';
import { RedirectModalContext } from '@/root-provider/redirect-modal-provider';
import { useIsFailurePage } from '@/utils/page-url';
import { cn } from '@/utils/tailwind';

import { useHeaderLinks } from '@/hooks/config/use-header-links';
import { AscendaLink } from './ascenda-link/ascenda-link';
import { BrandLogoLink } from './brand-logo-link/brand-logo-link';
import { linkClasses } from './constants';
import { FooterLink } from './footer-link/footer-link';

export type FooterProps =
  RewardsCentralConfiguration['application']['components']['footer'] &
    RewardsCentralConfiguration['application']['theme'];

export function Footer(props: FooterProps) {
  const isFailurePage = useIsFailurePage();

  return isFailurePage ? null : <FooterUi {...props}></FooterUi>;
}

export function FooterUi(props: FooterProps) {
  const isClient = typeof window !== 'undefined';
  const size = useSize(isClient ? document.body : undefined);

  const t = useTranslations('footer');
  const tenantHubConfig = useTenantHubConfig();
  const isConfigAvailable = !!props;
  const footerCopyStyles = cn({
    [props?.textAlign]: isConfigAvailable,
    [props?.textDecoration]: isConfigAvailable,
    [props?.textTransform]: isConfigAvailable,
  });

  const allRightsReservedText = t('allRightsReserved_year', {
    year: new Date().getFullYear(),
  });

  const footerLogo =
    props.logo === 'inverted' ? props.invertedLogo : props.brandLogo;

  const tenantHubBrandLogo = tenantHubConfig?.logo_url ?? footerLogo;
  const tenantHubBackgroundColor =
    tenantHubConfig?.footer_color ?? props.backgroundColor;

  // Logo: Use brandLogo from the theme, if footerLogo was not supplied
  return (
    <footer
      className={cn(
        'pb-8 pt-6 text-sm-normal lg:pt-8 lg:text-base-normal',
        'text-footer',
        footerCopyStyles,
      )}
      style={{
        backgroundColor: tenantHubBackgroundColor,
      }}
    >
      <Container className="flex flex-col gap-8">
        {props.variant === 'footer-1' || props.variant === 'footer-2' ? (
          <VariantOneAndTwoFooterLinks
            brandLogo={tenantHubBrandLogo}
            textAlign={props.textAlign}
            hideBranding={props.hideBranding}
          />
        ) : null}
        {props.variant === 'footer-3' || props.variant === 'footer-4' ? (
          <VariantThreeAndFourFooterLinks
            brandLogo={tenantHubBrandLogo}
            textAlign={props.textAlign}
            hideBranding={props.hideBranding}
          />
        ) : null}
        {props.variant === 'footer-5' || props.variant === 'footer-6' ? (
          <VariantFiveAndSixFooterLinks
            brandLogo={tenantHubBrandLogo}
            textAlign={props.textAlign}
            hideBranding={props.hideBranding}
          />
        ) : null}
        {props.variant === 'footer-7' || props.variant === 'footer-8' ? (
          <VariantSevenAndEightFooterLinks
            brandLogo={tenantHubBrandLogo}
            textAlign={props.textAlign}
            hideBranding={props.hideBranding}
            variant={props.variant}
          />
        ) : null}
        {props.variant === 'footer-9' ? (
          <VariantNineFooterLinks
            brandLogo={tenantHubBrandLogo}
            textAlign={props.textAlign}
            hideBranding={props.hideBranding}
            variant={props.variant}
          />
        ) : null}
        {props.variant === 'footer-2' ||
        props.variant === 'footer-4' ||
        props.variant === 'footer-6' ||
        props.variant === 'footer-7' ||
        props.variant === 'footer-8' ? (
          <Markdown
            className={cn(props.textAlign)}
            overrides={{
              a: ({
                node,
                ...props
              }: {
                node: WontFix;
                props: DetailedHTMLProps<
                  AnchorHTMLAttributes<HTMLAnchorElement>,
                  HTMLAnchorElement
                >;
              }) => {
                // only support open external link for now
                const isHrefUrl =
                  // @ts-expect-error: TODO fix later
                  props?.href?.startsWith('http');
                // DO NOT use RcLink or Next.js Link component here
                // https://kaligo.atlassian.net/browse/BUG-2482
                return isHrefUrl ? (
                  <a
                    {...props}
                    className={linkClasses}
                    target="_blank"
                    rel="noreferrer noopener"
                  />
                ) : (
                  <a {...props} className={linkClasses} />
                );
              },
            }}
          >
            {props?.content}
          </Markdown>
        ) : null}

        {props.hideBranding ? null : (
          <p className="text-sm-normal lg:text-base-normal">
            {allRightsReservedText}
          </p>
        )}
      </Container>
    </footer>
  );
}

function VariantOneAndTwoFooterLinks({
  brandLogo,
  textAlign,
  hideBranding,
}: {
  brandLogo: RewardsCentralConfiguration['application']['theme']['brandLogo'];
  textAlign: RewardsCentralConfiguration['application']['components']['footer']['textAlign'];
  hideBranding: RewardsCentralConfiguration['application']['components']['footer']['hideBranding'];
}) {
  const footerRcLinks = useFooterRcLinks();

  return (
    <div>
      <ul
        className={cn(
          'flex flex-wrap gap-x-6 gap-y-2 lg:flex-row lg:gap-x-10',
          {
            'justify-around': textAlign === 'text-center',
          },
        )}
        role="contentinfo"
      >
        {footerRcLinks.map((d) => (
          <li key={d.href}>
            <FooterLink data-testid="footer-link" href={d.href}>
              <p>{d.translation}</p>
            </FooterLink>
          </li>
        ))}

        <li className="mt-8 hidden flex-grow justify-end lg:mt-0 lg:block">
          <div className="flex w-full flex-col justify-center gap-4 lg:flex-row lg:justify-end">
            <BrandLogoLink logo={brandLogo} />
            <AscendaLink
              hideBranding={hideBranding}
              className={textAlign}
              data-testid="footer-logo"
            />
          </div>
        </li>
      </ul>
      <div className="mt-8 flex-grow justify-end lg:mt-0 lg:block lg:hidden">
        <div className="flex w-full flex-col justify-center gap-4 lg:flex-row lg:justify-end">
          <BrandLogoLink logo={brandLogo} />
          <AscendaLink
            hideBranding={hideBranding}
            className={textAlign}
            data-testid="footer-logo"
          />
        </div>
      </div>
    </div>
  );
}

function VariantThreeAndFourFooterLinks({
  brandLogo,
  textAlign,
  hideBranding,
}: {
  textAlign: RewardsCentralConfiguration['application']['components']['footer']['textAlign'];
  brandLogo: RewardsCentralConfiguration['application']['theme']['brandLogo'];
  hideBranding: RewardsCentralConfiguration['application']['components']['footer']['hideBranding'];
}) {
  const footerRcLinks = useFooterRcLinks();

  return (
    <>
      <ul
        className={cn(
          'flex flex-wrap gap-x-6 gap-y-2 lg:flex-row lg:gap-x-10',
          { 'justify-around': textAlign === 'text-center' },
        )}
        role="contentinfo"
      >
        <li className="hidden flex-grow lg:flex">
          <div className="flex w-full flex-col justify-center gap-4 lg:flex-row lg:justify-start">
            <BrandLogoLink logo={brandLogo} />
            <AscendaLink
              hideBranding={hideBranding}
              className={textAlign}
              data-testid="footer-logo"
            />
          </div>
        </li>

        {footerRcLinks.map((d) => (
          <li key={d.href}>
            <FooterLink href={d.href}>
              <p>{d.translation}</p>
            </FooterLink>
          </li>
        ))}
      </ul>
      <div className="mt-8 flex-grow justify-end lg:mt-0 lg:block lg:hidden">
        <div
          className={cn(
            'flex flex-col gap-4 lg:flex-row',
            { 'justify-center': textAlign === 'text-center' },
            { 'items-start': textAlign === 'text-left' },
          )}
        >
          <BrandLogoLink logo={brandLogo} />
          <AscendaLink
            hideBranding={hideBranding}
            className={textAlign}
            data-testid="footer-logo"
          />
        </div>
      </div>
    </>
  );
}

function VariantFiveAndSixFooterLinks({
  brandLogo,
  textAlign,
  hideBranding,
}: {
  textAlign: RewardsCentralConfiguration['application']['components']['footer']['textAlign'];
  brandLogo: RewardsCentralConfiguration['application']['theme']['brandLogo'];
  hideBranding: RewardsCentralConfiguration['application']['components']['footer']['hideBranding'];
}) {
  const footerRcLinks = useFooterRcLinks();

  return (
    <>
      <ul
        className={cn(
          'flex flex-wrap gap-x-6 gap-y-2 lg:mt-0 lg:flex-row lg:gap-x-10',
          { 'justify-center': textAlign === 'text-center' },
        )}
        role="contentinfo"
      >
        {footerRcLinks.map((d) => (
          <li key={d.href}>
            <FooterLink href={d.href}>
              <p>{d.translation}</p>
            </FooterLink>
          </li>
        ))}
      </ul>

      <div
        className={cn('mt-8 flex w-full flex-grow flex-col gap-6', {
          'items-center': textAlign === 'text-center',
        })}
      >
        <BrandLogoLink logo={brandLogo} />
        <AscendaLink hideBranding={hideBranding} data-testid="footer-logo" />
      </div>
    </>
  );
}

function VariantSevenAndEightFooterLinks({
  brandLogo,
  textAlign,
  hideBranding,
  variant,
}: {
  textAlign: RewardsCentralConfiguration['application']['components']['footer']['textAlign'];
  brandLogo: RewardsCentralConfiguration['application']['theme']['brandLogo'];
  hideBranding: RewardsCentralConfiguration['application']['components']['footer']['hideBranding'];
  variant: 'footer-7' | 'footer-8';
}) {
  const { headerLinks, needHelpLinks } = useHeaderLinks();
  const trackProductNavigation = useTrackProductNavigation();
  const trackTravelNavigation = useTrackTravelNavigation();
  const { setShowRedirectModal } = useContext(RedirectModalContext);

  const minPossibleGridCols = Math.min(
    Math.ceil(headerLinks.length / needHelpLinks.length),
    3,
  );
  const maxPossibleGridRows = Math.max(
    Math.ceil(headerLinks.length / 3),
    needHelpLinks.length,
  );

  return (
    <div
      className={cn(
        'flex flex-col gap-x-6 gap-y-8 footer-lg:flex-row footer-lg:justify-between',
        {
          'footer-lg:flex-row-reverse': variant === 'footer-8',
        },
      )}
    >
      <div className="flex flex-col gap-x-6 gap-y-8 footer-lg:flex-row">
        <div
          style={
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            {
              '--min-width': '336px',
              '--grid-cols': `repeat(${minPossibleGridCols},minmax(0,1fr))`,
              '--grid-rows': `repeat(${maxPossibleGridRows},minmax(0,1fr))`,
            } as CSSProperties
          }
          className={cn(
            'flex min-w-[var(--min-width)] flex-wrap gap-x-4 gap-y-2 md:gap-x-6 md:gap-y-4',
            'footer-lg:grid footer-lg:min-w-fit footer-lg:grid-cols-[var(--grid-cols)] footer-lg:grid-rows-[var(--grid-rows)]',
          )}
        >
          {headerLinks.map((link) => {
            return (
              <RcLink
                key={link.itemType}
                href={link.href}
                style={
                  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                  {
                    '--text-width': '160px',
                  } as CSSProperties
                }
                className="w-[var(--text-width)] font-normal capitalize text-sm-normal rc-break-anywhere lg:text-base-normal"
                onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                  customNavigation(link, event, setShowRedirectModal);
                  trackProductNavigation(link.itemType, 'footer');
                  trackTravelNavigation(link.itemType, 'footer');
                }}
              >
                {link.translation}
              </RcLink>
            );
          })}
        </div>

        <div
          style={
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            {
              '--text-width': '160px',
              '--min-width': '336px',
            } as CSSProperties
          }
          className={cn(
            'flex min-w-[var(--min-width)] flex-wrap gap-x-4 gap-y-2 md:gap-x-6 md:gap-y-4',
            'footer-lg:grid footer-lg:min-w-fit footer-lg:grid-cols-1',
          )}
        >
          {needHelpLinks.map((link) => (
            <RcLink
              key={link.translation}
              href={link.href}
              className="w-[var(--text-width)] font-normal capitalize text-sm-normal rc-break-anywhere lg:text-base-normal"
            >
              {link.translation}
            </RcLink>
          ))}
        </div>
      </div>

      <div className="flex flex-col gap-y-8 footer-lg:gap-y-3">
        <div
          className={cn(
            'flex h-fit flex-col gap-2',
            'footer-lg:w-fit footer-lg:flex-row footer-lg:items-center footer-lg:gap-4',
            {
              'items-center': textAlign === 'text-center',
              'w-auto items-start': textAlign === 'text-left',
              'items-end': textAlign === 'text-right',
            },
          )}
        >
          <BrandLogoLink logo={brandLogo} />
          {/**
           * NOTE(sontruong):
           * We should use 'whitespace-nowrap' for AscendaLink
           * to avoid layout mismatching with locale and currency selectors.
           */}
          <AscendaLink
            hideBranding={hideBranding}
            className={cn(
              textAlign,
              'whitespace-nowrap text-sm-normal lg:text-base-normal',
            )}
            data-testid="footer-logo"
          />
        </div>
        <div
          className={cn(
            variant === 'footer-8'
              ? 'footer-lg:items-start'
              : 'footer-lg:items-end',
            'flex flex-row gap-x-5 gap-y-2 footer-lg:flex-col',
          )}
        >
          <LocaleSelector isInFooter />
          <CurrencySelector isInFooter />
        </div>
      </div>
    </div>
  );
}

function VariantNineFooterLinks({
  brandLogo,
  textAlign,
  hideBranding,
}: {
  textAlign: RewardsCentralConfiguration['application']['components']['footer']['textAlign'];
  brandLogo: RewardsCentralConfiguration['application']['theme']['brandLogo'];
  hideBranding: RewardsCentralConfiguration['application']['components']['footer']['hideBranding'];
  variant: 'footer-9';
}) {
  const { needHelpLinks } = useHeaderLinks();

  return (
    <div
      className={cn(
        'flex flex-col lg:flex-row lg:justify-between',
        'gap-8 lg:gap-4',
      )}
    >
      <div
        className={cn(
          'grid grid-cols-3 gap-x-6 gap-y-2',
          'lg:grid-cols-[repeat(4,_max-content)] lg:!gap-16',
        )}
      >
        {needHelpLinks.map((link) => (
          <RcLink
            key={link.translation}
            href={link.href}
            className={cn(
              'font-normal capitalize text-sm-normal lg:text-base-normal',
              {
                'col-span-3 lg:col-span-1': link.translation.length > 20,
              },
            )}
          >
            {link.translation}
          </RcLink>
        ))}
      </div>

      <div className="flex flex-col gap-y-4 lg:items-end">
        <div
          className={cn(
            'flex flex-col lg:flex-row lg:items-center',
            'h-fit gap-2 lg:w-fit lg:gap-4',
            {
              'items-center': textAlign === 'text-center',
              'w-auto items-start': textAlign === 'text-left',
              'items-end': textAlign === 'text-right',
            },
          )}
        >
          <BrandLogoLink logo={brandLogo} />
          <AscendaLink
            hideBranding={hideBranding}
            className={cn(textAlign, 'whitespace-nowrap')}
            data-testid="footer-logo"
          />
        </div>
        <LocaleSelector isInFooter />
        <CurrencySelector isInFooter />
      </div>
    </div>
  );
}
