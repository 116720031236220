'use client';

import { useCarousel } from '@/hooks/carousel/use-carousel';
import { opacity } from '@/utils/opacity';
import { cn } from '@/utils/tailwind';
import { EmblaOptionsType, EmblaPluginType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import { Todo } from 'global';
import * as React from 'react';

export type CarouselProps = {
  opts?: EmblaOptionsType;
  plugins?: EmblaPluginType[];
  debug?: Todo;
};

type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0] | null;
  api: ReturnType<typeof useEmblaCarousel>[1] | null;
  onPrevButtonClick: () => void;
  onNextButtonClick: () => void;
  prevBtnDisabled: boolean;
  nextBtnDisabled: boolean;
  btnBgColor?: string;
  selectedIndex: number;
  scrollSnaps: number[];
  onDotButtonClick: (index: number) => void;
} & CarouselProps;

const CarouselContext = React.createContext<CarouselContextProps>({
  carouselRef: null,
  api: null,
  onPrevButtonClick: () => {},
  onNextButtonClick: () => {},
  prevBtnDisabled: true,
  nextBtnDisabled: true,
  btnBgColor: '',
  selectedIndex: 0,
  scrollSnaps: [],
  onDotButtonClick: () => {},
});

const Carousel = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & CarouselProps
>(({ opts, plugins, className, children, debug, ...props }, ref) => {
  const {
    emblaRef: carouselRef,
    emblaApi: api,

    selectedIndex,
    scrollSnaps,
    onDotButtonClick,

    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = useCarousel(opts, plugins);

  const primaryColor = getPrimaryColor();

  const btnBgColor = opacity(primaryColor, 0.5);

  return (
    <CarouselContext.Provider
      value={{
        carouselRef,
        api,
        opts,

        onPrevButtonClick,
        onNextButtonClick,
        prevBtnDisabled,
        nextBtnDisabled,
        btnBgColor,

        selectedIndex,
        scrollSnaps,
        onDotButtonClick,
      }}
    >
      <div
        ref={ref}
        className={cn('relative w-full', className)}
        role="region"
        aria-roledescription="carousel"
        {...props}
      >
        {children}
      </div>
    </CarouselContext.Provider>
  );
});
Carousel.displayName = 'Carousel';

const getPrimaryColor = () => {
  if (typeof window === 'undefined') return '#000000';

  return window
    .getComputedStyle(document.body)
    .getPropertyValue('--rc-color-primary');
};

const CarouselContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    viewableClassName?: string;
  }
>(({ className, viewableClassName, ...props }, ref) => {
  const { carouselRef } = React.useContext(CarouselContext);

  return (
    <div ref={carouselRef} className={cn('overflow-hidden', viewableClassName)}>
      <div ref={ref} className={cn('flex', className)} {...props} />
    </div>
  );
});
CarouselContent.displayName = 'CarouselContent';

const CarouselFadedEdges = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className }, ref) => {
  const { prevBtnDisabled, nextBtnDisabled } =
    React.useContext(CarouselContext);

  return (
    <div ref={ref} className="pointer-events-none">
      {!prevBtnDisabled ? (
        <div
          className={cn(
            `absolute left-0 top-0 h-full w-[20%] bg-gradient-to-r from-content-background via-transparent to-transparent`,
            className,
          )}
        ></div>
      ) : null}
      {!nextBtnDisabled ? (
        <div
          className={cn(
            `absolute right-0 top-0 h-full w-[20%] bg-gradient-to-l from-content-background via-transparent to-transparent`,
            className,
          )}
        ></div>
      ) : null}
    </div>
  );
});
CarouselFadedEdges.displayName = 'CarouselFadedEdges';

const CarouselItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ ...props }, ref) => {
  return <div ref={ref} role="group" aria-roledescription="slide" {...props} />;
});
CarouselItem.displayName = 'CarouselItem';

export {
  Carousel,
  CarouselContent,
  CarouselContext,
  CarouselFadedEdges,
  CarouselItem,
};
