'use client';

import { useAuthStore, useLocale } from '@/app/auth-store';
import { useRouter } from '@/i18n/navigation';
import { useAppEnv } from '@/store/store';
import { useMount } from 'ahooks';
import { useCallback, useEffect, useState } from 'react';
import { Modal, ModalOverlay } from 'react-aria-components';
import userflow from 'userflow.js';

const USER_FLOW_PRODUCTION_TOKEN = 'ct_q7iwqbjivfdutifb4xs57else4';
const USER_FLOW_STAGING_TOKEN = 'ct_kbmhunydwbeetolthzxecjpd24';

export default function UserFlowProvider() {
  const {
    state: { user },
  } = useAuthStore();
  const router = useRouter();
  const appEnv = useAppEnv();
  const locale = useLocale();

  const [backdropEnabled, setBackdropEnabled] = useState(false);
  const onResourceCenterChanged = useCallback(() => {
    const resourceCenterState = userflow.getResourceCenterState();
    setBackdropEnabled(!!resourceCenterState?.isOpen);
  }, []);

  useMount(() => {
    const userFlowToken =
      appEnv === 'production'
        ? USER_FLOW_PRODUCTION_TOKEN
        : USER_FLOW_STAGING_TOKEN;
    userflow.init(userFlowToken);
  });

  useEffect(() => {
    if (!user?.sub || !locale) return;

    userflow.identify(user?.sub, {
      locale_code: locale,
    });
    userflow.setCustomNavigate(router.push);
    userflow.setCustomScrollIntoView((element: Element) =>
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      }),
    );

    userflow.on('resourceCenterChanged', onResourceCenterChanged);

    return () => {
      userflow.off('resourceCenterChanged', onResourceCenterChanged);
    };
  }, [user?.sub, router, locale, onResourceCenterChanged]);

  return backdropEnabled ? (
    <ModalOverlay
      isOpen
      className="group fixed inset-0 z-50 grid items-end overflow-scroll bg-neutral-900 bg-opacity-70"
    >
      {/*
        Address this Sentry issue https://ascenda.sentry.io/performance/trace/4740021f6d8745e4b1f5aa17d912d1eb
        RAC throw error if ModalOverlay doesn't have any children.
      */}
      <Modal />
    </ModalOverlay>
  ) : null;
}
