'use client';
import { useHermes } from '@/auth/use-hermes';
import { useAuthFetchKy } from '@/hooks/fetch/use-auth-fetch-ky';
import {
  UserCustomizationsResponse,
  userCustomizationsSchema,
} from '@/schema/user-customization/user-customization.schema';
import { validateSchema } from '@/schema/validate-schema';
import { getClientUrl } from '@/utils/get-client-url';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSentryMonitoring } from '../monitoring/use-sentry-monitoring';

const responseFormatHeaders = new Headers({ 'x-response-format': 'custom' });

type UseUserCustomizationsParams<T> = {
  select?: (data: UserCustomizationsResponse) => T;
};

export function useUserCustomizations<T>({
  select,
}: UseUserCustomizationsParams<T>) {
  const baseUrl = getClientUrl();
  const path = `${baseUrl}/api/user_customizations`;
  const authFetch = useAuthFetchKy;
  const hermes = useHermes();
  const { sentryLog } = useSentryMonitoring();

  return useQuery({
    queryKey: ['user_customizations'],
    queryFn: async () => {
      const data = await authFetch
        .get(path, { headers: responseFormatHeaders })
        .json();

      const validatedData = validateSchema({
        data: data,
        schemaName: 'user-customizations-schema',
        zodSchema: userCustomizationsSchema,
      });

      return validatedData;
    },
    select,
    retry: 3,
    // make sure that we don't refetch this query
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: hermes.loggedIn,
  });
}

export function useUserCustomizationsMutation() {
  const queryClient = useQueryClient();
  const authedFetch = useAuthFetchKy;
  const path = `${getClientUrl()}/api/user_customizations`;

  return useMutation({
    mutationFn: async (requestData: {
      isAcceptedTc?: boolean;
      marketingEmailConsent?: boolean;
      termsVersion?: string;
      serviceTermsVersion?: string;
      policyVersion?: string;
      hideAwardSearchBanner?: boolean;
    }) => {
      return authedFetch.put(path, {
        json: { userCustomizations: requestData },
      });
    },
    onMutate: async (requestData) => {
      // https://tanstack.com/query/v4/docs/framework/react/guides/optimistic-updates

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['user_customizations'] });

      // Snapshot the previous value
      const previousCustomizations =
        queryClient.getQueryData<UserCustomizationsResponse>([
          'user_customizations',
        ]) || {};

      // Optimistically update to the new value
      queryClient.setQueryData(['user_customizations'], {
        ...previousCustomizations,
        ...requestData,
      });

      // Return a context with the previous and new customizations
      return { previousCustomizations, requestData };
    },
    // If the mutation fails, use the context we returned above
    onError: (_err, _requestData, context) => {
      queryClient.setQueryData(
        ['user_customizations'],
        context?.previousCustomizations,
      );
    },
  });
}
