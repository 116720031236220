export const symbolCookieNoCache = 'NEXT_NO_CACHE' as const;

export const symbolHeaderFlags = 'x-flags' as const;

export const symbolHeaderRequestId = 'x-request-id' as const;

export const serverRequestTimeout = 5e3 as const;

export const serverRedirects = [
  {
    source: '/products',
    destination: '/',
  },
  {
    source: '/rewards-summary',
    destination: '/rewards-summary/points-activity',
  },
  {
    source: '/travel',
    destination: '/travel/flights',
  },
] as const;
