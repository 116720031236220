export function opacity(hex: string, opacity: number): string {
  if (
    typeof hex !== 'string' ||
    !/^#([\dA-Fa-f]{3}$|[\dA-Fa-f]{6}$|[\dA-Fa-f]{8}$)/.test(hex)
  ) {
    return hex;
  }

  if (opacity > 1 || opacity < 0) {
    return hex;
  }

  let color = hex.slice(1);
  if (color.length === 8) color = color.slice(0, -2);
  if (color.length === 3) {
    const [r, g, b] = color as unknown as [string, string, string];
    color = r + r + g + g + b + b;
  }
  color += Math.round(opacity * 255)
    .toString(16)
    .padStart(2, '0');

  return `#${color}`.toUpperCase();
}
